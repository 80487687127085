export type TpStripeVariants =
  | 'default'
  | 'test'
  | 'Pilates'
  | 'PilatesGoogle'
  | 'PilatesPT'
  | 'PilatesTikTok'
  | 'PilatesVideo'
  | 'emailtraf'
  | 'variantemail4';

export type TpSource =
  | 'facebook'
  | 'twitter'
  | 'google'
  | 'tiktok'
  | 'pinterest';
export type TpPaymentSystem = 'braintree' | 'paddle' | 'stripe';

export type TpVariantObject = {
  variant_name: TpStripeVariants;
  payment_system: TpPaymentSystem;
  source: TpSource;
};

export const ALL_VARIANTS: Array<TpVariantObject> = [
  {
    variant_name: 'Pilates',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'test',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'PilatesPT',
    payment_system: 'stripe',
    source: 'pinterest',
  },
  {
    variant_name: 'PilatesTikTok',
    payment_system: 'stripe',
    source: 'tiktok',
  },
  {
    variant_name: 'PilatesVideo',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'PilatesGoogle',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'variantemail4',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'emailtraf',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'default',
    payment_system: 'stripe',
    source: 'facebook',
  },
];
