export const COOKIES_AB_TEST_NAME = 'Youth| Cookie';
export enum CookiesAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Cookie',
}

export const BREAKS_AB_TEST_NAME = 'Onboarding Breaks';
export enum BreaksAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Onboarding Breaks',
}

export const VIDEO_REVIEWS_TEST_NAME = 'video feedback';
export enum VideoReviewsAbTestCaseName {
  DEFAULT = 'A default',
  SHOW = 'B video feedback',
}

export const PERSONAL_PLAN_TEST_NAME = 'DEV-860 persosnal plan paywall';

export enum PersonalPlanAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B personal plan paywall',
}

export const ONBOARDING_JTBD_TEST_NAME =
  'DEV-866 | A/B test refresh onboarding JTBD';

export enum OnboardingJtbdAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B refresh JTBD',
}

export const PREPAYWALL_TEST_NAME = 'DEV-867 Pre-paywall';

export enum PrepaywallTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Long pre-paywall',
}

export const REFRESH_PAYWALL_TEST_NAME = 'DEV - 924 Refresh paywall';

export enum RefreshPaywallAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Refresh paywall',
}
