import { UiKitButton } from 'src/components/common/ui-kit-button';
import WeightTargetGraphLastPlanPilates from 'src/features/Quiz/components/WeightTargetGraphLastPlanPiltates';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { TpGoToNextStep } from '@features/Quiz/types';
import { FormattedMessage } from '@features/intl';

import styles from './LastPlan.module.scss';

type TpQuizStepQuestionProps = {
  goToNextStep: TpGoToNextStep;
};

export const PilatesPlanReadyChart = ({
  goToNextStep,
}: TpQuizStepQuestionProps) => (
  <StepContainer
    className={styles.container}
    isQuestionStep={false}
    background="PINK"
  >
    <WeightTargetGraphLastPlanPilates />
    <UiKitButton
      data-testid="last-plan-step-continue"
      className={styles.button}
      fullWidth
      onClick={() => goToNextStep({ eventName: 'onboarding_chart_tapped' })}
    >
      <FormattedMessage defaultMessage="Great!" id="Onboarding.Month.Button" />
    </UiKitButton>
  </StepContainer>
);
