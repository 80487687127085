import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { useIntl } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './LunchTime.module.scss';

export const LunchTime = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setLunchTime, getSingleOptionSelectStepValue },
    } = useStores();
    const intl = useIntl();
    const { options, stepName } = step;
    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setLunchTime(event.target.value);
        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_about_lunch_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [handleEventSend, setLunchTime],
    );

    return (
      <StepContainer isQuestionStep>
        <UiKitRadioGroup
          name="lunch-time"
          defaultValue={value}
          onChange={handleRadioChange}
          variant="rectangle"
          onClick={goToNextStep}
        >
          {options.map(
            ({ value, label, image, labelId, labelDefaultMessage }) => (
              <UiKitRadioOption
                key={`${stepName}_${value}`}
                label={
                  labelId
                    ? intl.formatMessage(
                        {
                          id: labelId,
                          defaultMessage: labelDefaultMessage,
                        },
                        {
                          br: <br />,
                        },
                      )
                    : label
                }
                value={value}
                startIcon={image}
                //TODO: refactor singleoption without unnecessary padding
                startIconClassName={styles.image}
                labelClassName={styles.label}
              />
            ),
          )}
        </UiKitRadioGroup>
      </StepContainer>
    );
  },
);
