import { ReactNode, memo, useCallback, useMemo } from 'react';

import { useAnalytics } from '@features/Analytics';
import { TpGoogleParameters } from '@features/Analytics/types';
import { AboutYouBreaks } from '@features/Quiz/PilatesOnboarding/steps/AboutYouBreaks';

import { LastPlanLong } from '@features/Quiz/PilatesOnboarding/steps/LastPlanLong';
import { PilatesPlanReadyChartLong } from '@features/Quiz/PilatesOnboarding/steps/PilatesPlanReadyChartLong';
import { QUIZ_STEPS_LIST, STEPS } from '../../config';
import {
  MultipleOptionsSelectQuizStep,
  TpPilatesQuizStepList,
  TpPilatesQuizStepProps,
  TpPilatesSingleOptionSelectQuizStep,
} from '../../types';
import { AdaptPlan } from '../AdaptPlan';
import { Age } from '../Age';
import { AnyDiet } from '../AnyDiet';
import { BadHabits } from '../BadHabits';
import { BestNoDiet } from '../BestNoDiet';
import { BestShape } from '../BestShape';
import { BestShapeV2 } from '../BestShapeV2';
import { BreakfastTime } from '../BreakfastTime';
import { ChooseYouth } from '../ChooseYouth';
import { CommitTo } from '../CommitTo';
import { DailyDrink } from '../DailyDrink';
import { DailyDrinkV2 } from '../DailyDrinkV2';
import { DinnerTime } from '../DinnerTime';
import { DreamBodyPilates } from '../DreamBodyPilates';
import { EatingHabits } from '../EatingHabits';
import { EnergyLevel } from '../EnergyLevel';
import { EnergyLevelV2 } from '../EnergyLevelV2';
import EnterEmail from '../EnterEmail';
import { EventComing } from '../EventComing';
import { Exercise } from '../Exercise';
import { ExerciseV2 } from '../ExerciseV2';
import { ExercisesAvoid } from '../ExercisesAvoid';
import { FitnessExperience } from '../FitnessExperience';
import { FlexiblePilates } from '../FlexiblePilates';
import { FlexiblePilatesV2 } from '../FlexiblePilatesV2';
import { GoalJTBD } from '../GoalJTBD';
import { GoalPilates } from '../GoalPilates';
import { GotGreatNews } from '../GotGreatNews';
import { Height } from '../Height';
import { HowFastReachGoal } from '../HowFastReachGoal';
import { LastPlan } from '../LastPlan';
import { LifeEvents } from '../LifeEvents';
import { LunchTime } from '../LunchTime';
import { MadeForYou } from '../MadeForYou';
import { MillionUsers } from '../MillionUsers';
import { NameStep } from '../NameStep';
import { NutritionBreak } from '../NutritionBreak';
import { OutOfBreath } from '../OutOfBreath';
import { PhysicalBuild } from '../PhysicalBuild';
import { PilatesPlanReadyChart } from '../PilatesPlanReadyChart';
import { PreferWorkout } from '../PreferWorkout';
import { ProvenEffectiveness } from '../ProvenEffectiveness';
import { SleepHygiene } from '../SleepHygiene';
import { StayFocused } from '../StayFocused';
import { StruggleWith } from '../StruggleWith';
import { TargetWeight } from '../TargetWeight';
import { TargetZones } from '../TargetZones';
import { TriedPilates } from '../TriedPilates';
import { TrustExperts } from '../TrustExperts';
import { TypicalDay } from '../TypicalDay';
import { UsuallySleep } from '../UsuallySleep';
import { Walks } from '../Walks';
import { Weight } from '../Weight';
import { WeightDynamic } from '../WeightDynamic';
import { Welcome } from '../Welcome';
import { WelcomeJtbd } from '../WelcomeJtbd';
import { WellnessProfile } from '../WellnessProfile';
import { WhenEvent } from '../WhenEvent';
import { WorkSchedule } from '../WorkSchedule';
import { YourBest } from '../YourBest';
import { YourCommunity } from '../YourCommunity';

export const PilatesWizardSteps = memo(
  ({ stepName, handleNextStep }: TpPilatesQuizStepProps) => {
    const { trackGoogleEvent } = useAnalytics();
    const handleEventSend = useCallback(
      ({ eventName, options }: TpGoogleParameters) => {
        trackGoogleEvent({
          eventName: eventName,
          options: options,
        });
      },
      [trackGoogleEvent],
    );

    const handleNextStepAndEventSend = useCallback(
      ({
        eventName,
        properties,
      }: {
        eventName: string;
        properties?: { [key: string]: string | number | boolean | null };
      }) => {
        trackGoogleEvent({
          eventName: eventName,
          options: properties,
        });
        handleNextStep();
      },
      [handleNextStep, trackGoogleEvent],
    );

    const stepComponents: Record<TpPilatesQuizStepList, ReactNode> = useMemo(
      () => ({
        [QUIZ_STEPS_LIST.WELCOME]: (
          <Welcome
            step={
              STEPS[
                QUIZ_STEPS_LIST.WELCOME
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WELCOME_JTBD]: (
          <WelcomeJtbd
            step={
              STEPS[
                QUIZ_STEPS_LIST.WELCOME_JTBD
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.CHOOSE_YOUTH]: (
          <ChooseYouth
            handleNextStep={() => {
              handleNextStepAndEventSend({
                eventName: 'onboarding_have_youth_opened',
              });
            }}
          />
        ),
        [QUIZ_STEPS_LIST.GOAL_PILATES]: (
          <GoalPilates
            step={
              STEPS[
                QUIZ_STEPS_LIST.GOAL_PILATES
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.GOAL_JTBD]: (
          <GoalJTBD
            step={
              STEPS[
                QUIZ_STEPS_LIST.GOAL_JTBD
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.PHYSICAL_BUILD]: (
          <PhysicalBuild
            step={
              STEPS[
                QUIZ_STEPS_LIST.PHYSICAL_BUILD
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.PHYSICAL_BUILD_V2]: (
          <PhysicalBuild
            step={
              STEPS[
                QUIZ_STEPS_LIST.PHYSICAL_BUILD_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.DREAM_BODY_PILATES]: (
          <DreamBodyPilates
            step={
              STEPS[
                QUIZ_STEPS_LIST.DREAM_BODY_PILATES
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.TARGET_ZONES]: (
          <TargetZones
            step={
              STEPS[
                QUIZ_STEPS_LIST.TARGET_ZONES
              ] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.STAY_FOCUSED]: (
          <StayFocused
            step={
              STEPS[
                QUIZ_STEPS_LIST.STAY_FOCUSED
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WEIGHT_DYNAMIC]: (
          <WeightDynamic
            step={
              STEPS[
                QUIZ_STEPS_LIST.WEIGHT_DYNAMIC
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.BEST_SHAPE_V2]: (
          <BestShapeV2
            step={
              STEPS[
                QUIZ_STEPS_LIST.BEST_SHAPE_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.FLEXIBLE_PILATES_V2]: (
          <FlexiblePilatesV2
            step={
              STEPS[
                QUIZ_STEPS_LIST.FLEXIBLE_PILATES_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.EXERCISE_V2]: (
          <ExerciseV2
            step={
              STEPS[
                QUIZ_STEPS_LIST.EXERCISE_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.FITNESS_EXPERIENCE]: (
          <FitnessExperience
            step={
              STEPS[
                QUIZ_STEPS_LIST.FITNESS_EXPERIENCE
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.BEST_SHAPE]: (
          <BestShape
            step={
              STEPS[
                QUIZ_STEPS_LIST.BEST_SHAPE
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.TRIED_PILATES]: (
          <TriedPilates
            step={
              STEPS[
                QUIZ_STEPS_LIST.TRIED_PILATES
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.FLEXIBLE_PILATES]: (
          <FlexiblePilates
            step={
              STEPS[
                QUIZ_STEPS_LIST.FLEXIBLE_PILATES
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.EXERCISE]: (
          <Exercise
            step={
              STEPS[
                QUIZ_STEPS_LIST.EXERCISE
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.OUT_OF_BREATH]: (
          <OutOfBreath
            step={
              STEPS[
                QUIZ_STEPS_LIST.OUT_OF_BREATH
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.OUT_OF_BREATH_V2]: (
          <OutOfBreath
            step={
              STEPS[
                QUIZ_STEPS_LIST.OUT_OF_BREATH_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WALKS]: (
          <Walks
            step={
              STEPS[
                QUIZ_STEPS_LIST.WALKS
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WALKS_V2]: (
          <Walks
            step={
              STEPS[
                QUIZ_STEPS_LIST.WALKS_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.STRUGGLE_WITH]: (
          <StruggleWith
            step={
              STEPS[
                QUIZ_STEPS_LIST.STRUGGLE_WITH
              ] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.STRUGGLE_WITH_V2]: (
          <StruggleWith
            step={
              STEPS[
                QUIZ_STEPS_LIST.STRUGGLE_WITH_V2
              ] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.EXERCISES_AVOID]: (
          <ExercisesAvoid
            step={
              STEPS[
                QUIZ_STEPS_LIST.EXERCISES_AVOID
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WORK_SCHEDULE]: (
          <WorkSchedule
            step={
              STEPS[
                QUIZ_STEPS_LIST.WORK_SCHEDULE
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.TYPICAL_DAY]: (
          <TypicalDay
            step={
              STEPS[
                QUIZ_STEPS_LIST.TYPICAL_DAY
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.TYPICAL_DAY_V2]: (
          <TypicalDay
            step={
              STEPS[
                QUIZ_STEPS_LIST.TYPICAL_DAY_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.PREFER_WORKOUT]: (
          <PreferWorkout
            step={
              STEPS[
                QUIZ_STEPS_LIST.PREFER_WORKOUT
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ENERGY_LEVEL]: (
          <EnergyLevel
            step={
              STEPS[
                QUIZ_STEPS_LIST.ENERGY_LEVEL
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ENERGY_LEVEL_V2]: (
          <EnergyLevelV2
            step={
              STEPS[
                QUIZ_STEPS_LIST.ENERGY_LEVEL_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.USUALLY_SLEEP]: (
          <UsuallySleep
            step={
              STEPS[
                QUIZ_STEPS_LIST.USUALLY_SLEEP
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.DAILY_DRINK]: (
          <DailyDrink
            step={
              STEPS[
                QUIZ_STEPS_LIST.DAILY_DRINK
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.DAILY_DRINK_V2]: (
          <DailyDrinkV2
            step={
              STEPS[
                QUIZ_STEPS_LIST.DAILY_DRINK_V2
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.BREAKFAST_TIME]: (
          <BreakfastTime
            step={
              STEPS[
                QUIZ_STEPS_LIST.BREAKFAST_TIME
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.EATING_HABITS]: (
          <EatingHabits
            step={
              STEPS[
                QUIZ_STEPS_LIST.EATING_HABITS
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.HOW_FAST_REACH_GOAL]: (
          <HowFastReachGoal
            step={
              STEPS[
                QUIZ_STEPS_LIST.HOW_FAST_REACH_GOAL
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.LUNCH_TIME]: (
          <LunchTime
            step={
              STEPS[
                QUIZ_STEPS_LIST.LUNCH_TIME
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.DINNER_TIME]: (
          <DinnerTime
            step={
              STEPS[
                QUIZ_STEPS_LIST.DINNER_TIME
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ANY_DIET]: (
          <AnyDiet
            step={
              STEPS[
                QUIZ_STEPS_LIST.ANY_DIET
              ] as TpPilatesSingleOptionSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.BAD_HABITS]: (
          <BadHabits
            step={
              STEPS[QUIZ_STEPS_LIST.BAD_HABITS] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.LIFE_EVENTS]: (
          <LifeEvents
            step={
              STEPS[
                QUIZ_STEPS_LIST.LIFE_EVENTS
              ] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.HEIGHT]: (
          <Height goToNextStep={handleNextStepAndEventSend} />
        ),
        [QUIZ_STEPS_LIST.CURRENT_WEIGHT]: (
          <Weight goToNextStep={handleNextStepAndEventSend} />
        ),
        [QUIZ_STEPS_LIST.TARGET_WEIGHT]: (
          <TargetWeight goToNextStep={handleNextStepAndEventSend} />
        ),
        [QUIZ_STEPS_LIST.EVENT_COMING]: (
          <EventComing
            step={
              STEPS[
                QUIZ_STEPS_LIST.EVENT_COMING
              ] as MultipleOptionsSelectQuizStep
            }
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ENTER_EMAIL]: (
          <EnterEmail
            step={STEPS[QUIZ_STEPS_LIST.ENTER_EMAIL]}
            goToNextStep={handleNextStep}
          />
        ),
        [QUIZ_STEPS_LIST.AGE]: (
          <Age
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
            step={
              STEPS[QUIZ_STEPS_LIST.AGE] as TpPilatesSingleOptionSelectQuizStep
            }
          />
        ),
        [QUIZ_STEPS_LIST.NAME]: (
          <NameStep
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
            step={
              STEPS[QUIZ_STEPS_LIST.NAME] as TpPilatesSingleOptionSelectQuizStep
            }
          />
        ),
        [QUIZ_STEPS_LIST.LAST_PLAN]: (
          <LastPlan
            step={STEPS[QUIZ_STEPS_LIST.LAST_PLAN]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.LAST_PLAN_LONG]: (
          <LastPlanLong
            step={STEPS[QUIZ_STEPS_LIST.LAST_PLAN_LONG]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WELLNESS_PROFILE]: (
          <WellnessProfile
            step={STEPS[QUIZ_STEPS_LIST.WELLNESS_PROFILE]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.MILLION_USERS]: (
          <MillionUsers
            step={STEPS[QUIZ_STEPS_LIST.MILLION_USERS]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.WHEN_EVENT]: (
          <WhenEvent
            goToNextStep={handleNextStep}
            handleEventSend={handleEventSend}
            step={
              STEPS[
                QUIZ_STEPS_LIST.WHEN_EVENT
              ] as TpPilatesSingleOptionSelectQuizStep
            }
          />
        ),
        [QUIZ_STEPS_LIST.PLAN_READY_CHART]: (
          <PilatesPlanReadyChart goToNextStep={handleNextStepAndEventSend} />
        ),
        [QUIZ_STEPS_LIST.PLAN_READY_CHART_LONG]: (
          <PilatesPlanReadyChartLong
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.MADE_FOR_YOU]: (
          <MadeForYou
            step={STEPS[QUIZ_STEPS_LIST.MADE_FOR_YOU]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.COMMIT_TO]: (
          <CommitTo
            step={STEPS[QUIZ_STEPS_LIST.COMMIT_TO]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.GOT_GREAT_NEWS]: (
          <GotGreatNews
            step={STEPS[QUIZ_STEPS_LIST.GOT_GREAT_NEWS]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.PROVEN_EFFECTIVENESS]: (
          <ProvenEffectiveness
            step={STEPS[QUIZ_STEPS_LIST.PROVEN_EFFECTIVENESS]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.TRUST_EXPERTS]: (
          <TrustExperts
            step={STEPS[QUIZ_STEPS_LIST.TRUST_EXPERTS]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ADAPT_PLAN]: (
          <AdaptPlan
            step={STEPS[QUIZ_STEPS_LIST.ADAPT_PLAN]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.NUTRITION_BREAK]: (
          <NutritionBreak
            step={STEPS[QUIZ_STEPS_LIST.NUTRITION_BREAK]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.ABOUT_YOU_BREAKS]: (
          <AboutYouBreaks
            step={STEPS[QUIZ_STEPS_LIST.ABOUT_YOU_BREAKS]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.BEST_NO_DIET]: (
          <BestNoDiet
            step={STEPS[QUIZ_STEPS_LIST.BEST_NO_DIET]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.YOUR_BEST]: (
          <YourBest
            step={STEPS[QUIZ_STEPS_LIST.YOUR_BEST]}
            goToNextStep={handleNextStepAndEventSend}
          />
        ),
        [QUIZ_STEPS_LIST.SLEEP_HYGIENE]: (
          <SleepHygiene
            step={STEPS[QUIZ_STEPS_LIST.SLEEP_HYGIENE]}
            goToNextStep={handleNextStep}
          />
        ),
        [QUIZ_STEPS_LIST.YOUR_COMMUNITY]: (
          <YourCommunity
            step={STEPS[QUIZ_STEPS_LIST.YOUR_COMMUNITY]}
            goToNextStep={handleNextStep}
          />
        ),
      }),
      [handleEventSend, handleNextStep, handleNextStepAndEventSend],
    );

    return stepComponents[stepName] || <div>Step Does Not Exist</div>;
  },
);

PilatesWizardSteps.displayName = 'PilatesWizardSteps';
