import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback, useState } from 'react';

import { Image } from 'src/components/common/Image';
import { CookieAgreement } from 'src/components/common/cookie-agreement';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';
import {
  COOKIES_AB_TEST_NAME,
  CookiesAbTestCaseName,
} from 'src/constants/analytics';
import { EURO_COUNTRIES } from 'src/constants/payment';

import { TpMessageKeys, useIntl } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';

export const GOAL_OPTIONS = [
  {
    value: 'lose_weight',
    labelId: 'Onboarding.Goal.Option.Weight',
    labelDefaultMessage: 'Lose weight',
    image: <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />,
  },
  {
    value: 'build_muscle_strength',
    labelId: 'Onboarding.Goal.Option.Strenght',
    labelDefaultMessage: 'Build muscle strenght',
    image: (
      <Image
        width={40}
        height={40}
        src="emoji-man-lifting-weights.webp"
        alt=""
      />
    ),
  },
  {
    value: 'develop_flexibility',
    labelId: 'Onboarding.Goal.Option.Flexibility',
    labelDefaultMessage: 'Increase flexibility',
    image: <Image width={40} height={40} src="emoji-dancer.webp" alt="" />,
  },
  {
    value: 'reduce_stress_anxiety',
    labelDefaultMessage: 'Reduce stress & anxiety',
    labelId: 'Onboarding.Goal.Option.Stress',
    image: <Image width={40} height={40} src="emoji-yoga-woman.webp" alt="" />,
  },
  {
    value: 'improve_posture',
    labelDefaultMessage: 'Improve posture',
    labelId: 'Onboarding.Goal.Option.Posture',
    image: (
      <Image width={40} height={40} src="emoji-woman-walking.webp" alt="" />
    ),
  },
];

export const GoalPilates = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: {
        countryCode,
        setGoalPilates,
        getSingleOptionSelectStepValue,
      },
      analyticsStore: { getCaseNameByTestName },
    } = useStores();

    const intl = useIntl();

    const [isCookieAgreementShow, setIsCookieAgreementShow] = useState(false);

    const cookieAgreementFromStorage =
      typeof window !== 'undefined' &&
      sessionStorage.getItem('cookie-agreement');

    const isCookieAvailable =
      getCaseNameByTestName(COOKIES_AB_TEST_NAME) === CookiesAbTestCaseName.NEW;

    const isCookieAgreementRender =
      cookieAgreementFromStorage !== 'agreed' &&
      isCookieAvailable &&
      countryCode &&
      EURO_COUNTRIES.includes(countryCode);

    const { stepName } = step;

    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setGoalPilates(event.target.value);
        if (isCookieAgreementRender) {
          setIsCookieAgreementShow(true);
        } else {
          goToNextStep();
        }

        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_goal_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [goToNextStep, handleEventSend, isCookieAgreementRender, setGoalPilates],
    );

    const handleAgree = useCallback(() => {
      sessionStorage.setItem('cookie-agreement', 'agreed');
      setIsCookieAgreementShow(false);
      goToNextStep();
    }, [goToNextStep]);

    return (
      <div>
        <UiKitRadioGroup
          name="goal-pilates"
          defaultValue={value}
          onChange={handleRadioChange}
          variant="rectangle"
          onClick={goToNextStep}
        >
          {GOAL_OPTIONS.map(
            ({ value, image, labelId, labelDefaultMessage }) => (
              <UiKitRadioOption
                key={`${stepName}_${value}`}
                label={
                  labelId
                    ? intl.formatMessage(
                        {
                          id: labelId as TpMessageKeys,
                          defaultMessage: labelDefaultMessage,
                        },
                        {
                          br: <br />,
                        },
                      )
                    : labelDefaultMessage
                }
                value={value}
                startIcon={image}
              />
            ),
          )}
        </UiKitRadioGroup>
        {isCookieAgreementShow && isCookieAgreementRender && (
          <CookieAgreement onAgree={handleAgree} />
        )}
      </div>
    );
  },
);
