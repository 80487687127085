import { ParentSize } from '@visx/responsive';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import GraphImage from 'src/components/main/quiz/graph/graph-image/graph-image';

import { WeightUnits } from '@features/Quiz/constants';
import { FormattedMessage, useIntl } from '@features/intl';

import styles from './WeightTargetGraphLastPlanPiltates.module.scss';

const LABEL_COLORS = ['#F7544A', '#FEA46D', '#F7EE9F', '#A2E4F9'];
const startLabel = {
  backgroundProps: {
    rx: 8,
    fill: '#fff',
    stroke: 'var(--gray-middle, #E7E7EC)',
    strokeWidth: 1,
  },
  titleFontWeight: '400',
  fontColor: '#000',
  backgroundPadding: { left: 12, right: 12, top: 6, bottom: 6 },
};

const labelGradient = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="239"
    height="189"
    viewBox="0 0 239 189"
    fill="none"
  >
    <defs>
      <linearGradient
        id="paint0_linear_5964_19318"
        x1="-15.6951"
        y1="-31.2094"
        x2="280.903"
        y2="-15.5455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF564B" />
        <stop offset="0.536458" stopColor="#FFE789" />
        <stop offset="1" stopColor="#A2E3F9" />
      </linearGradient>
    </defs>
  </svg>
);

const WeightTargetGraphLastPlanPilates = observer(() => {
  const { quizStore, authStore } = useStores();
  const intl = useIntl();
  const {
    weightValue,
    weightUnits,
    userName,
    whenEvent,
    goalWeightValue,
    weightPerDay = 0.2,
  } = quizStore;
  const { user } = authStore;

  const userWeightValue = weightValue || user?.weight_value;
  const userWeightUnits = weightUnits || (user?.weight_units as WeightUnits);

  if (userWeightValue == null || userWeightUnits == null) {
    throw new Error('Weight and goal weight steps must be completed first');
  }

  const maxWeight = userWeightValue;
  let minWeight = +(maxWeight - 4.2).toFixed(0);
  let middleWeight = +(maxWeight - 1.4).toFixed(0);
  let thirdWeight = +(maxWeight - 2.8).toFixed(0);

  const formattedToday = dayjs().format('DD.MM.YYYY');
  const formattedWhenEvent = dayjs(whenEvent).format('DD.MM.YYYY');

  if (
    whenEvent != null &&
    formattedWhenEvent !== formattedToday &&
    goalWeightValue != null
  ) {
    middleWeight = +(maxWeight - 7 * weightPerDay).toFixed(0);
    thirdWeight = +(maxWeight - 14 * weightPerDay).toFixed(0);
    minWeight = +(maxWeight - 21 * weightPerDay).toFixed(0);
  }

  const weekMessage = intl.formatMessage({
    asString: true,
    id: 'Onboarding.Month.Week',
    defaultMessage: 'week',
  });

  const rangeMonth = [
    `1 ${weekMessage}`,
    `2 ${weekMessage}`,
    `3 ${weekMessage}`,
    `4 ${weekMessage}`,
  ];

  const weights = [maxWeight, middleWeight, thirdWeight, minWeight];

  const lineData = rangeMonth.map((_, unitIndex) => {
    const value = weights[unitIndex];
    const stylesLabel = startLabel;
    return {
      unitIndex,
      value,
      labelValue: value,
      stylesLabel,
    };
  });

  const yourWeightMessage = intl.formatMessage({
    asString: true,
    id: 'Onboarding.OnlyPlan.YourWeight',
    defaultMessage: 'Your weight',
  });

  return (
    <figure className={classNames(styles.container)}>
      <h2 className={styles.name}>{userName},</h2>
      <p className={styles.description}>
        <FormattedMessage
          defaultMessage="your 4-week Pilates Workout Plan is ready!"
          id="Onboarding.Month.Title"
        />
      </p>
      <ParentSize>
        {({ width }) => (
          <GraphImage
            lineData={lineData}
            unitLabels={rangeMonth}
            minWeight={minWeight}
            maxWeight={userWeightValue}
            weightUnits={userWeightUnits}
            width={width}
            height={272}
            labelColors={LABEL_COLORS}
            labelGradient={labelGradient}
            rightUpperCornerText={yourWeightMessage as string}
          />
        )}
      </ParentSize>
      <p className={styles.footerDescription}>
        <FormattedMessage
          id="Onboarding.Month.Text"
          defaultMessage="This chart is for illustrative purposes only"
        />
      </p>
    </figure>
  );
});

export default WeightTargetGraphLastPlanPilates;
