import Hotjar from '@hotjar/browser';
import { useEffect } from 'react';

import { HOTJAR_ID } from '@features/Analytics';

const HOTJAR_VERSION = 6;

export const HotjarComponent = () => {
  useEffect(() => {
    Hotjar.init(Number(HOTJAR_ID), HOTJAR_VERSION);
  }, []);
  return null;
};
