import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';

import { TpQuizStepQuestionProps } from '../../types';

export const BreakfastTime = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setBreakfastTime },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOption
        doubleImageWidth
        stepName={stepName}
        name="breakfast-time"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setBreakfastTime}
        goToNextStep={goToNextStep}
        eventName="onboarding_usually_breakfast_chosen"
      />
    );
  },
);
