import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { isDevelopment } from 'src/utils';

import Clarity from '@features/Analytics/components/Clarity';
import { HotjarComponent } from '@features/Analytics/components/Hotjar';
import { PinterestPixel } from '@features/Analytics/components/PinterestPixel';
import TikTokPixel from '@features/Analytics/components/TikTokPixel';
import TwitterPixel from '@features/Analytics/components/TwitterPixel';
import { useShouldInit } from '@features/Analytics/hooks';

import { DebugUi } from '../DebugUi';
import FbPixel from '../FacebookPixel';
import { GoogleAnalytics, GoogleTagManager } from '../GoogleAnalytics';

export const AnalyticsInject = observer(() => {
  const {
    authStore: { variant },
  } = useStores();

  const {
    shouldPixelInit,
    shouldPTInit,
    shouldTwitterInit,
    shouldTikTokInit,
    shouldGoogleAnalyticsInit,
  } = useShouldInit(variant);

  return (
    <>
      {!isDevelopment && <HotjarComponent />}
      {shouldPixelInit && <FbPixel />}
      {shouldGoogleAnalyticsInit && (
        <>
          <GoogleTagManager />
          <GoogleAnalytics />
        </>
      )}

      {shouldPTInit && <PinterestPixel />}
      {shouldTwitterInit && <TwitterPixel />}
      {shouldTikTokInit && (
        <TikTokPixel isPilatesId={variant === 'PilatesTikTok'} />
      )}
      {!isDevelopment && <Clarity />}
      {isDevelopment && <DebugUi />}
    </>
  );
});
