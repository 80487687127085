import classNames from 'classnames';
import { useCallback } from 'react';

import { UiKitButton } from 'src/components/common/ui-kit-button';
import { UiKitCheckboxGroup } from 'src/components/common/ui-kit-checkbox-group';
import { UiKitCheckboxOption } from 'src/components/common/ui-kit-checkbox-option';

import { TpMultipleOptionsPilatesStepProps } from '@features/Quiz/PilatesOnboarding/types';
import { useIntl } from '@features/intl';

import styles from './MultipleOptionsPilates.module.scss';

const MultipleOptionsPilatesSelectStep = ({
  className,
  optionsContainerClassName,
  suffix,
  options,
  value,
  nextButtonText = 'Next',
  nextButtonId,
  onChange,
  onSubmit,
  skipButtonText,
  skipButtonId,
  skipButtonIcon,
  extraContent,
  startIconClassName,
  goToNextStep,
  skipOptionText,
}: TpMultipleOptionsPilatesStepProps) => {
  const intl = useIntl();
  const skipHandleClick = useCallback(() => {
    if (goToNextStep) {
      goToNextStep();
      onChange(null);
    }
  }, [onChange, goToNextStep]);

  return (
    <form
      className={classNames(styles.container, className)}
      action="#"
      onSubmit={(event) => {
        event.preventDefault();
        if (!value) {
          onChange([]);
        }
        onSubmit();
      }}
    >
      <UiKitCheckboxGroup
        className={classNames(
          styles.optionsContainer,
          optionsContainerClassName,
        )}
      >
        {options.map((option) => {
          const optionSuffix = `${suffix}_${option.value}`;
          return (
            <UiKitCheckboxOption
              key={optionSuffix}
              suffix={optionSuffix}
              label={
                option.labelId
                  ? intl.formatMessage({
                      id: option.labelId,
                      defaultMessage: option.labelDefaultMessage,
                    })
                  : option.label
              }
              value={option.value}
              checked={value?.includes(option.value) ?? false}
              onChange={(checked) => {
                const newValue = checked
                  ? [...(value ?? []), option.value]
                  : value!.filter(
                      (optionValue) => optionValue !== option.value,
                    );
                onChange(newValue.length > 0 ? newValue : undefined);
              }}
              startIcon={option.image && option.image}
              startIconClassName={classNames(styles.image, startIconClassName)}
              labelClassName={styles.labelClassname}
            />
          );
        })}
        {skipOptionText != null && (
          <UiKitCheckboxOption
            suffix={`${suffix}_skip`}
            checked={value?.length === 0}
            label={
              skipOptionText.labelId
                ? intl.formatMessage(
                    {
                      id: skipOptionText.labelId,
                      defaultMessage: skipOptionText.labelDefaultMessage,
                    },
                    {
                      br: <br />,
                    },
                  )
                : skipOptionText.label
            }
            value={skipOptionText.value}
            onChange={(checked) => onChange(checked ? [] : undefined)}
            startIcon={skipOptionText.image}
            startIconClassName={classNames(styles.image, startIconClassName)}
            labelClassName={styles.labelClassname}
          />
        )}
      </UiKitCheckboxGroup>
      {extraContent}
      {skipButtonId ? (
        <UiKitButton
          color="secondary"
          className={styles.skipButton}
          type="submit"
          fullWidth
          onClick={skipHandleClick}
        >
          {intl.formatMessage({
            id: skipButtonId,
            defaultMessage: skipButtonId as string,
          })}
        </UiKitButton>
      ) : (
        skipButtonText != null &&
        !skipButtonIcon && (
          <UiKitButton
            color="secondary"
            className={styles.skipButton}
            type="submit"
            fullWidth
            onClick={skipHandleClick}
          >
            {skipButtonText}
          </UiKitButton>
        )
      )}
      <UiKitButton
        className={styles.submitButton}
        type="submit"
        disabled={!value}
        fullWidth
        data-testid="next-button"
      >
        {nextButtonId
          ? intl.formatMessage({
              id: nextButtonId,
              defaultMessage: nextButtonId as string,
            })
          : nextButtonText}
      </UiKitButton>
    </form>
  );
};

export default MultipleOptionsPilatesSelectStep;
