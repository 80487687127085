import { get, post } from 'src/api/api';
import { SubscriptionPlanSnapshot } from 'src/models/subscription-plan/subscription-plan';

export const PAYMENT_ENDPOINT = process.env
  .NEXT_PUBLIC_API_PAYMENT_ENDPOINT as string;

const responseToSubscriptionPlan = (
  response: any,
): SubscriptionPlanSnapshot => ({
  id: response['id'],
  name: response['name'],
  currency: response['currency'],
  priceAfterIntroOffer: response['price_after_intro_offer'],
  specialOffer: response['special_offer'],
  discountPercentage: response['discount_percentage'],
  billingPeriod: response['billing_period'],
  billingPeriodUnit: response['billing_period_unit'],
  trialPeriodDays: response['trial_period_days'],
  displayPrice: response['display_price'],
  fractionalPrice: +response['fractional_price'],
  fractionalPriceDiscount: +response['fractional_price_discount'],
  fullPrice: +response['full_price'],
  initialPrice:
    response['initial_price'] == null ? undefined : +response['initial_price'],
  fullPriceDiscount: +response['full_price_discount'],
  group: response['group'],
  description: response['description'],
  merchantAccountId: response['merchant_account_id'] ?? undefined,
  order: response['order'],
  paddlePlanId:
    response['paddle_plan_id'] == null
      ? undefined
      : +response['paddle_plan_id'],
  paypalPlanId: response['paypal_plan_id'] ?? undefined,
  stripePriceId: response['stripe_price_id'] ?? undefined,
  nuveiPlanId:
    response['nuvei_plan_id'] == null ? undefined : +response['nuvei_plan_id'],
  braintreePlanId:
    response['braintree_plan_id'] == null
      ? undefined
      : response['braintree_plan_id'],
  hideDescription:
    response['hide_description'] == null
      ? undefined
      : response['hide_description'],
  gift: response['gift'] == null ? undefined : response['gift'],
  preselected:
    response['preselected'] == null ? undefined : response['preselected'],
});

export const fetchSubscriptionPlans = async ({
  abortSignal,
  group,
}: {
  abortSignal?: AbortSignal;
  group?: string;
}): Promise<SubscriptionPlanSnapshot[]> => {
  const responseObject = await get({
    endpoint: PAYMENT_ENDPOINT,
    path: 'subscription_plans',
    abortSignal,
    params: { group: group ?? '' },
  });
  return responseObject.map((planResponseObject: any) =>
    responseToSubscriptionPlan(planResponseObject),
  );
};

export const generateBraintreeClientToken = async ({
  customerId,
  customerEmail,
  abortSignal,
  merchantAccountId,
}: {
  customerId: string;
  customerEmail: string;
  abortSignal?: AbortSignal;
  merchantAccountId?: string;
}): Promise<string> =>
  await post({
    endpoint: PAYMENT_ENDPOINT,
    path: 'braintree_generate_client_token',
    body: {
      app: 'Youth',
      user_id: customerId,
      email: customerEmail,
      merchant_account_id: merchantAccountId,
    },
    abortSignal,
  });

export const createBraintreeSubscription = async ({
  customerId,
  customerToken,
  merchantAccountId,
  planId,
  paymentMethodNonce,
  paymentMethodVaulted = true,
  isStripePAth,
  abortSignal,
}: {
  customerId: string;
  customerToken: string;
  merchantAccountId?: string;
  planId: string;
  paymentMethodNonce: string;
  paymentMethodVaulted?: boolean;
  isStripePAth?: boolean;
  abortSignal?: AbortSignal;
}): Promise<void> =>
  await post({
    endpoint: PAYMENT_ENDPOINT,
    path: isStripePAth
      ? 'braintree_create_subscription_from_stripe'
      : 'braintree_create_subscription',
    body: {
      app: 'Youth',
      user_id: customerId,
      token: customerToken,
      plan_id: planId,
      payment_method_nonce: paymentMethodNonce,
      payment_method_vaulted: paymentMethodVaulted,
      merchant_account_id: merchantAccountId,
    },
    abortSignal,
  });
