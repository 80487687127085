import { UiKitButton } from 'src/components/common/ui-kit-button';
import WeightTargetGraphLastPlan from 'src/components/main/quiz/graph-pilates/weight-target-graph-last-plan';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { FormattedMessage } from '@features/intl';

import { TpSummaryStepProps } from '../../types';
import styles from './LastPlan.module.scss';

export const LastPlan = ({ goToNextStep }: TpSummaryStepProps) => (
  <>
    <StepContainer
      className={styles.container}
      isQuestionStep={false}
      background="PINK"
    >
      <h2 className={styles.title}>
        <FormattedMessage
          defaultMessage="The {mark} you’ll ever need to get in shape"
          id="Onboarding.OnlyPlan.Title"
          values={{
            mark: (
              <b style={{ color: '#FF3F82' }}>
                <FormattedMessage
                  defaultMessage="only plan"
                  id="Onboarding.OnlyPlan.Title.Mark"
                />
              </b>
            ),
          }}
        />
        <FormattedMessage
          defaultMessage=" you’ll ever"
          id="Onboarding.OnlyPlan.Title:36"
        />{' '}
        <FormattedMessage
          defaultMessage="need to get in shape"
          id="Onboarding.OnlyPlan.Title:56"
        />
      </h2>
      <WeightTargetGraphLastPlan />
    </StepContainer>
    <UiKitButton
      className={styles.submitButton}
      type="submit"
      fullWidth
      onClick={() => goToNextStep({ eventName: 'onboarding_chart_tapped' })}
      data-testid="next-button"
    >
      <FormattedMessage defaultMessage="Great!" id="Onboarding.Month.Button" />
    </UiKitButton>
  </>
);
